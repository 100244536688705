import { AxiosError } from "axios";
import { ErrorResponse, NewZebraBrowserPrint, PricingProcessor, ZebraPrinter } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/common/Button";
import Camera, { CameraRef } from "../../components/common/Camera";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { convertUserImage } from "../../utils/camera";
import { getLocalSettings } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";

export default function SearchPricing({ getItemsCount }: { getItemsCount: () => void }) {
    const navigate = useNavigate();
    const { processes: processApi } = useContext(ApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();

    const cameraRef = useRef<CameraRef>(null);
    const mobileCameraRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getItemsCount();

        // get default label printer
        if (canPrint() && !printerLoaded.current) {
            printerLoaded.current = true;

            NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
                .getDefaultPrinter()
                .then((printer) => (selectedPrinter.current = printer))
                .catch((error) => toast(`Error getting default printer: ${error}`));
        }

        // open camera automatically on mobile when page loads
        if (isMobile) mobileCameraRef?.current?.click();
    }, []);

    const getBody = () => {
        if (isLoading) {
            return (
                <div className="w-full">
                    <Loader />
                </div>
            );
        }

        if (isMobile) {
            return (
                <div className="flex w-full flex-col gap-4 p-8">
                    <Button
                        textColor="text-thriftlyGreyDark"
                        backgroundColor="bg-thriftlyOffWhite"
                        className={`h-32 w-full border-thriftlyGrey text-2xl`}
                        onClick={() => {
                            mobileCameraRef?.current?.click();
                        }}
                    >
                        Take picture
                    </Button>
                    {error && <p className="text-md text-center text-red-500">{error}</p>}
                    <input
                        ref={mobileCameraRef}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                            if (e.target.files === null) {
                                toast("No file selected");
                                return;
                            }

                            // upload image
                            setIsLoading(true);
                            processApi
                                ?.processesCreate(
                                    {
                                        image: new File([e.target.files[0]], "image", { type: "image/png" }),
                                    },
                                    {
                                        processor: PricingProcessor.ProcessorSerpApi,
                                        stationType: getLocalSettings().stationType,
                                        skipInventory: true,
                                    }
                                )
                                .then(async (resp) => {
                                    navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                                })
                                .catch((err: AxiosError) => {
                                    setError(
                                        (err.response?.data as ErrorResponse)?.error ||
                                            "An error occurred. Please try again."
                                    );
                                });
                        }}
                    />
                </div>
            );
        }

        return (
            <Camera
                cameraId="1"
                ref={cameraRef}
                onImageCapture={async (
                    userImage: string,
                    setCameraIsLoading: (isLoading: boolean) => void,
                    closeCamera: () => void
                ) => {
                    setCameraIsLoading(true);
                    setIsLoading(true);
                    setError("");
                    // upload image
                    await processApi
                        ?.processesCreate(
                            {
                                image: convertUserImage(userImage),
                            },
                            {
                                processor: PricingProcessor.ProcessorSerpApi,
                                stationType: getLocalSettings().stationType,
                                skipInventory: true,
                            }
                        )
                        .then(async (resp) => {
                            setCameraIsLoading(false);
                            closeCamera();
                            navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                        })
                        .catch((err: AxiosError) => {
                            setError(
                                (err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again."
                            );
                        });
                }}
            >
                <div className="flex flex-col gap-4">
                    <Button
                        textColor="text-thriftlyGreyDark"
                        backgroundColor="bg-thriftlyOffWhite"
                        className={`h-32 w-full border-thriftlyGrey text-2xl`}
                        onClick={() => {
                            cameraRef?.current?.capture();
                        }}
                    >
                        Search
                    </Button>
                    {error && <p className="text-md text-center text-red-500">{error}</p>}
                </div>
            </Camera>
        );
    };

    return getBody();
}
