import { Department } from "common";

export default function DepartmentTile({
    department,
    isSelected,
    onClick,
    first,
    last,
}: {
    department: Department;
    isSelected: boolean;
    onClick: () => void;
    first?: boolean;
    last?: boolean;
}) {
    return (
        <div
            key={department.id}
            onClick={onClick}
            className={`flex h-16 cursor-pointer flex-row items-center gap-2 py-2 ${isSelected ? "border-2 border-thriftlyGreyLight bg-thriftlyGreyDark px-[32px]" : "border border-thriftlyGreyDark border-b-transparent px-[33px]"} ${first ? "rounded-tl-lg" : ""} ${last ? "rounded-tr-lg" : ""} ${!isSelected && !last ? "border-r-transparent" : ""} `}
        >
            {department.icon && <img src={department.icon} className="h-8 w-8" />}
            <div className="h-8 text-xl text-thriftlyOffWhite">{department.name}</div>
        </div>
    );
}
