import { AxiosError } from "axios";
import { ErrorResponse, StationType, UserRole } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import CustomDropdownBlack from "../../components/common/CustomDropdownBlack";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import {
    getCurrentColorCycle,
    getHangers,
    getLocalSettings,
    getOfflineItems,
    getPriceAppConfig,
    setCategories,
    setCurrentColorCycle,
    setDepartments,
    setHangers,
    setLabelLogo,
    setLocalSettings,
    setOfflineItems,
    setPriceAppConfig,
    setPricingEntries,
    setTaxonomies,
    setTopLevelTaxonomies,
} from "../../utils/localStorage";
import RapidPricing from "./RapidPricing";
import SearchPricing from "./SearchPricing";
import OfflinePricing, { OfflinePricingRef } from "./TwoClickOfflinePricing";
import TwoClickPricing from "./TwoClickPricing";

export default function PricerPage() {
    const [searchParams] = useSearchParams();
    const { VITE_API_WEBSOCKET_URL } = import.meta.env;
    const { orgId, role, email } = useContext(OrgContext);
    const {
        processes: processApi,
        organizations: organizationsApi,
        pricing: pricingApi,
        taxonomies: taxonomiesApi,
        departments: departmentsApi,
        categories: categoriesApi,
        login: usersApi,
    } = useContext(ApiContext);
    const {
        lastMessage: barcodeLastMessage,
        sendJsonMessage: barcodeSendJsonMessage,
        readyState: barcodeReadyState,
    } = useWebSocket("ws://localhost:8089", {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
    });
    const {
        lastMessage: apiLastMessage,
        sendJsonMessage: apiSendJsonMessage,
        readyState: apiReadyState,
    } = useWebSocket(VITE_API_WEBSOCKET_URL, {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
        onOpen: () => {
            uploadOfflineItems();
        },
    });

    const [stationType, setStationType] = useState(getLocalSettings().stationType);
    const [selectedHanger, setSelectedHanger] = useState(getLocalSettings().selectedHanger);
    const [itemsCount, setItemsCount] = useState<number>();
    const [offlineMode, setOfflineMode] = useState(false);
    const [offlinePricingActive, setOfflinePricingActive] = useState(false);
    const offlinePricingRef = useRef<OfflinePricingRef>(null);

    const getItemsCount = () => {
        processApi
            ?.itemsTodayList()
            .then((resp) => {
                setItemsCount(resp.data.itemsToday);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    // Cache information necessary for the pricer page and offline production
    useEffect(() => {
        // cache the pricing app config
        organizationsApi
            ?.priceAppConfigDetail(orgId)
            .then((response) => {
                if (response.data.priceAppConfig) {
                    setPriceAppConfig(response.data.priceAppConfig);
                    setLabelLogo(response.data.logo);
                    setCurrentColorCycle(response.data.colorCycle);
                }
            })
            .catch((error) => toast(`Error getting pricing app config printer: ${error}`));

        // cache hangers
        usersApi
            ?.usersList()
            .then((response) => {
                if (response.data.users) setHangers(response.data.users);
            })
            .catch((error) => toast(`Error getting hangers: ${error}`));

        // cache departments
        departmentsApi
            ?.departmentsList()
            .then((resp) => {
                if (resp.data.departments) setDepartments(resp.data.departments);
            })
            .catch((error) => toast(`Error getting departments: ${error}`));

        // cache categories
        categoriesApi
            ?.categoriesList()
            .then((resp) => {
                if (resp.data.categories) setCategories(resp.data.categories);
            })
            .catch((error) => toast(`Error getting categories: ${error}`));

        // cache the pricing entries
        pricingApi
            ?.pricingList()
            .then((response) => {
                if (response.data.pricingEntries) setPricingEntries(response.data.pricingEntries);
            })
            .catch((error) => toast(`Error getting pricing entries: ${error}`));

        // cache the taxonomy categories
        taxonomiesApi
            ?.taxonomiesList()
            .then((response) => {
                if (response.data.taxonomies) setTaxonomies(response.data.taxonomies);
                if (response.data.topLevelTaxonomies) setTopLevelTaxonomies(response.data.topLevelTaxonomies);
            })
            .catch((error) => toast(`Error getting taxonomies: ${error}`));

        // cache images used by offline pricing component
        const images = ["/barcode_scanner_qr_code.svg"];
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, []);

    // Handle api websocket keep-alive and determine offline mode
    useEffect(() => {
        if (apiReadyState === ReadyState.OPEN && apiLastMessage?.data === "ping") {
            apiSendJsonMessage("pong");
        }
    }, [apiLastMessage]);
    useEffect(() => {
        if (apiReadyState === ReadyState.CLOSING || apiReadyState === ReadyState.CLOSED) {
            setOfflineMode(true);
        } else if (apiReadyState === ReadyState.OPEN && !offlinePricingActive) {
            setOfflineMode(false);
        }
    }, [apiReadyState, offlinePricingActive]);

    const uploadOfflineItems = () => {
        if (getOfflineItems().length == 0 || offlinePricingRef.current?.isActive()) {
            return;
        }

        processApi
            ?.uploadCreate({ processes: getOfflineItems() })
            .then(() => {
                toast("Offline items uploaded successfully");
                setOfflineItems([]);
            })
            .catch(() => {
                toast("Error uploading items priced offline");
            });
    };

    const getBody = () => {
        if (searchParams.get("search") !== null || isMobile) {
            return <SearchPricing getItemsCount={getItemsCount} />;
        }

        if (getPriceAppConfig().twoClickPricing && getLocalSettings().stationType === StationType.StationTypeSoftline) {
            // two click pricing offline mode
            if (offlineMode) {
                return (
                    <OfflinePricing
                        lastMessage={barcodeLastMessage}
                        setItemsCount={setItemsCount}
                        setOfflinePricingActive={setOfflinePricingActive}
                    />
                );
            }

            // two click pricing if the barcode agent is available
            else if (barcodeReadyState === ReadyState.OPEN) {
                return (
                    <TwoClickPricing
                        lastMessage={barcodeLastMessage}
                        sendJsonMessage={barcodeSendJsonMessage}
                        getItemsCount={getItemsCount}
                    />
                );
            }
        }

        // TODO: offline rapid pricing

        // otherwise, rapid pricing
        return (
            <RapidPricing
                offlineMode={offlineMode}
                barcodeScannerAgentReady={barcodeReadyState === ReadyState.OPEN}
                lastBarcodeScannerMessage={barcodeLastMessage}
                sendBarcodeScannerJsonMessage={barcodeSendJsonMessage}
                getItemsCount={getItemsCount}
            />
        );
    };

    return (
        <>
            <div className="flex h-dvh flex-col overflow-y-clip overscroll-none">
                {offlineMode && <div className="bg-yellow-500 text-center text-white">Offline production mode</div>}
                <Header
                    darkMode
                    left={
                        <div className="flex flex-row items-center gap-4 pl-4">
                            <MenuPopover role={role as UserRole} darkMode />
                            {!isMobile && (
                                <div className="flex h-[20px] shrink-0 items-center justify-end gap-2">
                                    <div
                                        className="cursor-pointer rounded bg-thriftlyGreyDark px-2 py-1 text-xs uppercase text-thriftlyOffWhite"
                                        onClick={() => {
                                            const newStationType =
                                                stationType === StationType.StationTypeHardline
                                                    ? StationType.StationTypeSoftline
                                                    : StationType.StationTypeHardline;
                                            setStationType(newStationType);
                                            setLocalSettings({ ...getLocalSettings(), stationType: newStationType });
                                        }}
                                    >
                                        {stationType === StationType.StationTypeHardline ? "HARD GOODS" : "SOFT GOODS"}
                                    </div>
                                    {getCurrentColorCycle() && (
                                        <div
                                            style={{ backgroundColor: getCurrentColorCycle()?.toLowerCase() }}
                                            className={`rounded px-2 py-1`}
                                        >
                                            <div className="text-thriftlyBackgroundBlack text-xs uppercase mix-blend-hard-light">
                                                {getCurrentColorCycle()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    }
                    right={
                        <div className="flex flex-row items-center justify-end gap-4 pr-4">
                            {!isMobile && getPriceAppConfig().trackHanger && getHangers().length > 0 && (
                                <CustomDropdownBlack
                                    label="Hanger"
                                    placeholder="Select hanger"
                                    horizontal
                                    value={selectedHanger?.id}
                                    onChange={(hangerId) => {
                                        setSelectedHanger(getHangers().find((hanger) => hanger.id === hangerId));
                                        setLocalSettings({
                                            ...getLocalSettings(),
                                            selectedHanger: getHangers().find((hanger) => hanger.id === hangerId),
                                        });
                                    }}
                                    options={getHangers().map((hanger) => ({
                                        label: hanger.name!,
                                        value: hanger.id!,
                                    }))}
                                />
                            )}
                            {!isMobile && <div className="text-thriftlyOffWhite">{email}</div>}
                            <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                        </div>
                    }
                />
                <div className="flex h-full w-full flex-col items-center justify-center border-t-2 border-thriftlyGreyDark">
                    {getBody()}
                </div>
            </div>
        </>
    );
}
