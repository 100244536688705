import { Process } from "common";
import { Button } from "../../../components/common/Button";

export default function BrandAlertModal({
    process,
    onOverride,
    onClose,
}: {
    process: Process;
    onOverride: () => void;
    onClose: () => void;
}) {
    return (
        <>
            <div className="absolute left-0 top-0 h-full w-full bg-thriftlyGreyDark opacity-75" />
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                <div className="max-h-3/4 flex w-1/2 flex-col gap-1 rounded-thriftlyImage bg-thriftlyOffWhite px-16 py-8">
                    <div className="text-xl font-light uppercase">Copilot alert</div>
                    <div className="text-3xl font-bold">Send to e-commerce</div>
                    <div className="text-xl font-light">{process?.brand} is a premium brand</div>
                    <div className="w-full overflow-hidden rounded-thriftlyImage border-2 bg-thriftlyGreyDark">
                        <img
                            className="max-h-screen-4/5 object-contain"
                            src={process?.images ? process?.images[0] : ""}
                            alt="item image"
                        />
                    </div>
                    <div className="flex flex-row justify-between gap-8 pt-4">
                        <Button className="border-1 w-full border-black" onClick={onOverride}>
                            Override and keep in store
                        </Button>
                        <Button className="w-full text-white" backgroundColor="bg-thriftlyGreyDark" onClick={onClose}>
                            Item set aside for e-commerce
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
