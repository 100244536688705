import { capitalize, PricingProcessor, StationType, UserRole } from "common";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdPreview } from "react-icons/md";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import CustomDropdownBlack from "../../components/common/CustomDropdownBlack";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import { cameraFormat } from "../../utils/camera";
import {
    CameraSettings,
    getLocalSettings as getLocalSettingsLocalStorage,
    LocalSettings,
    setLocalSettings as setLocalSettingsLocalStorage,
} from "../../utils/localStorage";
import GeneratedInputText from "../ProcessPage/Components/GeneratedInputField";

type CameraOption = {
    id: string;
    name: string;
};

export default function SettingsPage() {
    const { role, email } = useContext(OrgContext);
    const [localSettings, setLocalSettings] = useState<LocalSettings>(getLocalSettingsLocalStorage());
    const [cameras, setCameras] = useState<CameraOption[]>([]);

    const [showCameraPreview, setShowCameraPreview] = useState(false);
    const [cameraPreviewId, setCameraPreviewId] = useState<string | undefined>();

    const setCamera1Settings = (camera1: CameraSettings) => {
        setLocalSettings({ ...localSettings, camera1: camera1 });
    };

    const setCamera2Settings = (camera2: CameraSettings) => {
        setLocalSettings({ ...localSettings, camera2: camera2 });
    };

    useEffect(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                const cameras = devices.filter((device) => device.kind === "videoinput");
                setCameras(cameras.map((camera) => ({ id: camera.deviceId, name: camera.label })));
            })
            .catch((error) => {
                toast.error(`Error getting cameras: ${error}`);
            });
    }, []);

    useEffect(() => {
        setLocalSettingsLocalStorage(localSettings);
    }, [localSettings]);

    const cameraOptions = [{ label: "Disabled", value: "" }].concat(
        cameras.map((camera) => {
            return {
                label: camera.name,
                value: camera.id,
            };
        })
    );

    const cameraRotationOptions = [
        { label: "0°", value: "0" },
        // { label: "90°", value: "90" },
        { label: "180°", value: "180" },
        // { label: "270°", value: "270" },
    ];

    const cameraFormatOptions = [
        { label: "Square", value: "square" },
        { label: "Landscape", value: "landscape" },
        { label: "Portrait", value: "portrait" },
    ];

    const cameraResolutionOptions = [
        { label: "480p", value: "480p" },
        { label: "720p", value: "720p" },
        { label: "1080p", value: "1080p" },
    ];

    const previewCamera = (cameraId: string) => {
        setShowCameraPreview(true);
        setCameraPreviewId(cameraId);
    };

    const getPreviewCameraSettings = () => {
        return cameraPreviewId === "2" ? localSettings.camera2 : localSettings.camera1;
    };

    return (
        <div className="h-dvh overflow-y-clip overscroll-none">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && <div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>}
            />
            <div className="flex flex-col items-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex min-h-24 w-96 flex-col gap-2">
                    <div className="text-center text-2xl text-white">Settings</div>
                    <div className="grid grid-cols-2 items-center">
                        <div className="text-white">Station type</div>
                        <CustomDropdownBlack
                            placeholder="Select station type"
                            options={Object.values(StationType).map((option) => {
                                return {
                                    label: capitalize(option.replace("_", " ")),
                                    value: option,
                                };
                            })}
                            value={localSettings.stationType}
                            capitalize
                            onChange={(value) => {
                                setLocalSettings({ ...localSettings, stationType: value as StationType });
                            }}
                        />
                        <div className="text-white">Camera(s)</div>
                        <CustomDropdownBlack
                            options={[
                                { label: "Enabled", value: "true" },
                                { label: "Disabled", value: "false" },
                            ]}
                            value={localSettings.cameraEnabled}
                            onChange={(value) => {
                                setLocalSettings({ ...localSettings, cameraEnabled: value });
                            }}
                        />
                    </div>
                    {!isMobile && localSettings.cameraEnabled === "true" && (
                        <>
                            <div className="text-center text-xl text-white">Camera 1</div>
                            <div className="text-s text-center text-thriftlyGreyLight">
                                Used for full frame pictures
                            </div>
                            <div className="grid grid-cols-2 items-center">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="text-white">Camera</div>
                                    <MdPreview
                                        className="cursor-pointer text-white"
                                        size={24}
                                        onClick={() => previewCamera("1")}
                                    />
                                </div>
                                <CustomDropdownBlack
                                    options={cameraOptions}
                                    value={localSettings.camera1?.id}
                                    onChange={(value) => {
                                        setCamera1Settings({ ...localSettings.camera1, id: value });
                                    }}
                                />
                                {localSettings.camera1?.id !== "" && (
                                    <>
                                        <div className="text-white">Rotation</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera rotation"
                                            options={cameraRotationOptions}
                                            value={localSettings.camera1?.rotation}
                                            onChange={(value) => {
                                                setCamera1Settings({ ...localSettings.camera1, rotation: value });
                                            }}
                                        />
                                        <div className="text-white">Format</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera format"
                                            options={cameraFormatOptions}
                                            value={localSettings.camera1?.format}
                                            onChange={(value) => {
                                                setCamera1Settings({ ...localSettings.camera1, format: value });
                                            }}
                                        />
                                        <div className="text-white">Resolution</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera resolution"
                                            options={cameraResolutionOptions}
                                            value={localSettings.camera1?.resolution}
                                            onChange={(value) => {
                                                setCamera1Settings({ ...localSettings.camera1, resolution: value });
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="flex flex-row items-center justify-center gap-4">
                                <div className="text-center text-xl text-white">Camera 2</div>
                                <div className="text-s text-center text-thriftlyGreyLight">
                                    Used for size tag pictures
                                </div>
                            </div>
                            <div className="grid grid-cols-2 items-center">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="text-white">Camera</div>
                                    <MdPreview
                                        className="cursor-pointer text-white"
                                        size={24}
                                        onClick={() => previewCamera("2")}
                                    />
                                </div>
                                <CustomDropdownBlack
                                    options={cameraOptions}
                                    value={localSettings.camera2?.id}
                                    onChange={(value) => {
                                        setCamera2Settings({ ...localSettings.camera2, id: value });
                                    }}
                                />
                                {localSettings.camera2?.id !== "" && (
                                    <>
                                        <div className="text-white">Rotation</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera rotation"
                                            options={cameraRotationOptions}
                                            value={localSettings.camera2?.rotation}
                                            onChange={(value) => {
                                                setCamera2Settings({ ...localSettings.camera2, rotation: value });
                                            }}
                                        />
                                        <div className="text-white">Format</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera format"
                                            options={cameraFormatOptions}
                                            value={localSettings.camera2?.format}
                                            onChange={(value) => {
                                                setCamera2Settings({ ...localSettings.camera2, format: value });
                                            }}
                                        />
                                        <div className="text-white">Resolution</div>
                                        <CustomDropdownBlack
                                            placeholder="Select camera resolution"
                                            options={cameraResolutionOptions}
                                            value={localSettings.camera2?.resolution}
                                            onChange={(value) => {
                                                setCamera2Settings({ ...localSettings.camera2, resolution: value });
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className="text-center text-xl text-white">AI Copilot</div>
                    <div className="grid grid-cols-2 items-center">
                        <div className="text-white">Processor</div>
                        <CustomDropdownBlack
                            placeholder="Select a processor"
                            options={Object.values(PricingProcessor)
                                .filter((option) => option !== PricingProcessor.ProcessorSerpApi)
                                .map((option) => {
                                    return {
                                        label: capitalize(option.replace("_", " ")),
                                        value: option,
                                    };
                                })}
                            value={localSettings.aiGenerateProcessor}
                            capitalize
                            onChange={(value) => {
                                setLocalSettings({
                                    ...localSettings,
                                    aiGenerateProcessor: value as PricingProcessor,
                                });
                            }}
                        />
                        <div className="text-white">Image Count</div>
                        <CustomDropdownBlack
                            placeholder="Select image count"
                            options={[
                                { label: "2", value: "2" },
                                { label: "3", value: "3" },
                                { label: "4", value: "4" },
                                { label: "5", value: "5" },
                                { label: "6", value: "6" },
                                { label: "7", value: "7" },
                                { label: "8", value: "8" },
                                { label: "9", value: "9" },
                                { label: "10", value: "10" },
                            ]}
                            value={localSettings.aiAssistantImageCount}
                            onChange={(value) => {
                                setLocalSettings({ ...localSettings, aiAssistantImageCount: value });
                            }}
                        />
                    </div>
                    <div className="text-center text-xl text-white">Labels</div>
                    <div className="grid grid-cols-2 items-center">
                        <div className="text-white">Printer Server</div>
                        <div className="p-2">
                            <GeneratedInputText
                                labelTextColor="text-thriftlyGreyLight"
                                textColor="text-thriftlyOffWhite"
                                backgroundColor="bg-thriftlyBlackBackground"
                                placeholder="127.0.0.1:9100"
                                type="text"
                                value={localSettings.labelPrinterServer}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, labelPrinterServer: value });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showCameraPreview && (
                <div onClick={() => setShowCameraPreview(false)}>
                    <div className="absolute left-0 top-0 h-full w-full bg-thriftlyGreyDark opacity-75" />
                    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                        <div className="max-h-3/4 flex w-1/2 flex-col gap-1 rounded-thriftlyImage bg-thriftlyOffWhite">
                            <Webcam
                                audio={false}
                                className={`w-full transform rotate-${getPreviewCameraSettings()?.rotation} overflow-hidden rounded-thriftlyCamera`}
                                screenshotFormat="image/png"
                                mirrored={false}
                                screenshotQuality={1}
                                imageSmoothing={false}
                                videoConstraints={{
                                    deviceId: getPreviewCameraSettings()?.id,
                                    facingMode: "environment",
                                    aspectRatio: cameraFormat(getPreviewCameraSettings()),
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
