import { Category } from "common";

export default function CategoryTile({
    category,
    isSelected,
    onClick,
}: {
    category: Category;
    isSelected: boolean;
    onClick: () => void;
    first?: boolean;
    last?: boolean;
}) {
    return (
        <div
            key={category.id}
            onClick={onClick}
            className={`flex h-[200px] w-[200px] cursor-pointer gap-2 rounded-lg text-thriftlyOffWhite ${isSelected ? "border-2 border-thriftlyGreyLight bg-thriftlyGreyDark" : "border border-thriftlyGreyDark"} `}
        >
            <div className="flex h-full w-full flex-col items-center justify-center p-2 pt-4 text-center">
                {category.icon && <img src={category.icon} className="mb-auto h-32" />}
                <div className="w-full text-xl font-semibold">{category.name}</div>
            </div>
        </div>
    );
}
