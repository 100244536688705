import { Process } from "common";
import { forwardRef } from "react";
import QRCode from "react-qr-code";
import { getLabelLogo } from "../../utils/localStorage";

export default forwardRef(function LabelRenderer(props: { process: Process }) {
    const { process } = props;
    const { VITE_PRICING_APP_URL } = import.meta.env;

    return (
        <div className="flex h-[456px] w-[242px] flex-col items-center justify-between bg-white p-4 text-center">
            {getLabelLogo() ? (
                <img
                    className="max-h-[50px] w-[242px] object-scale-down"
                    src={`data:image/png;base64,${getLabelLogo()}`}
                    alt="logo"
                />
            ) : (
                <div className="h-[45px] w-[242px] text-4xl" style={{ overflow: "hidden", overflowWrap: "break-word" }}>
                    thriftly
                </div>
            )}
            <div className="w-[242px] text-center text-6xl font-semibold">
                ${parseFloat(process.price?.replace("$", "") || "0").toFixed(2)}
            </div>
            <QRCode className="mt-2" value={`${VITE_PRICING_APP_URL}/shop/${process.id}`} size={150} />
            <div
                className="h-[50px] w-[242px] text-5xl font-semibold"
                style={{ overflow: "hidden", overflowWrap: "break-word" }}
            >
                {process.size}
            </div>
            <div className="line-clamp-2 w-[242px] text-2xl">{process.category}</div>
        </div>
    );
});
