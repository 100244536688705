import { Condition, isSet, NewZebraBrowserPrint, PricingProcessor, Process, ZebraPrinter } from "common";
import { toPng } from "html-to-image";
import { useContext, useEffect, useRef, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import { OrgContext } from "../../components/context/OrgContext";
import { rotate } from "../../utils/camera";
import {
    getLocalSettings,
    getOfflineItems,
    getPricingEntries,
    getTaxonomies,
    setOfflineItems,
} from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";
import GeneratedInputText from "../ProcessPage/Components/GeneratedInputField";
import ScanTile from "../ProcessPage/Components/ScanTile";
import LabelRenderer from "./LabelRenderer";

export interface OfflinePricingRef {
    isActive(): boolean;
}

export default function TwoClickOfflinePricing({
    lastMessage,
    setItemsCount,
    setOfflinePricingActive,
}: {
    lastMessage: MessageEvent<string> | null;
    setItemsCount: (count: number) => void;
    setOfflinePricingActive: (active: boolean) => void;
}) {
    const { orgId, userId, locationId } = useContext(OrgContext);
    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();
    const [size, setSize] = useState<string | undefined>(undefined);
    const sizeRef = useRef<string | undefined>(size);
    const [sizeSet, setSizeSet] = useState<boolean>(false);
    const sizeSetRef = useRef<boolean>(sizeSet);
    const [showPriceLabelModal, setShowPriceLabelModal] = useState(false);
    const labelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // get default label printer
        if (canPrint() && !printerLoaded.current) {
            printerLoaded.current = true;

            NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
                .getDefaultPrinter()
                .then((printer) => (selectedPrinter.current = printer))
                .catch((error) => toast(`Error getting default printer: ${error}`));
        }

        setItemsCount(getOfflineItems().length);
    }, []);

    useEffect(() => {
        sizeRef.current = size;
        sizeSetRef.current = sizeSet;
        setOfflinePricingActive(sizeSetRef.current || isSet(sizeRef.current));
    }, [size, sizeSet]);

    useEffect(() => {
        if (!showPriceLabelModal) return;

        printLabel();
    }, [showPriceLabelModal]);

    const createItem = (process: Process) => {
        setOfflineItems([...getOfflineItems(), process]);
        setItemsCount(getOfflineItems().length);
        toast.success("Item successfully priced");
        setSizeSet(false);
        setSize(undefined);
        setShowPriceLabelModal(true);
    };

    const printLabel = async () => {
        await new Promise((r) => setTimeout(r, 500));

        toPng(labelRef.current!, { cacheBust: false })
            .then((dataUrl) => {
                rotate(dataUrl, 270)
                    .then((rotatedDataUrl) => {
                        fetch(rotatedDataUrl)
                            .then((res) => res.blob())
                            .then(async (blob) => {
                                selectedPrinter?.current?.printPNGFromBlob(blob).catch((error) => {
                                    toast(`Error printing label: ${error}`);
                                    console.error(`Error printing label: ${error}`);
                                });
                                await new Promise((r) => setTimeout(r, 500));
                                setShowPriceLabelModal(false);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSizeTagBody = () => {
        if (sizeSet) {
            return (
                <div className="flex flex-col gap-4">
                    {size ? <div>Size: {size}</div> : <span className="text-thriftlyGreyLight">Size Skipped</span>}
                    <a
                        className="cursor-pointer underline"
                        onClick={() => {
                            setSize(undefined);
                            setSizeSet(false);
                        }}
                    >
                        Redo
                    </a>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-4 text-thriftlyGreyLight">
                <div>or input the size:</div>
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    label="Size"
                    placeholder="Size"
                    type="text"
                    value={size || ""}
                    onChange={setSize}
                    onEnter={() => {
                        setSizeSet(true);
                    }}
                />
                <div>
                    or{" "}
                    <a
                        className="cursor-pointer underline"
                        onClick={() => {
                            setSize(undefined);
                            setSizeSet(true);
                        }}
                    >
                        skip this step.
                    </a>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex h-full w-full flex-row gap-4 p-8">
                <div className="flex w-1/2">
                    <ScanTile
                        state={sizeSet ? "completed" : "active"}
                        title="1. Scan the size qr code or enter size manually"
                        image={sizeSet ? undefined : "/barcode_scanner_qr_code.svg"}
                    >
                        {getSizeTagBody()}
                    </ScanTile>
                </div>
                <div className="flex w-1/2">
                    <ScanTile
                        state={sizeSet ? "active" : "disabled"}
                        title="2. Scan the category and grade QR code"
                        image="/barcode_scanner_qr_code.svg"
                    />
                </div>
            </div>
            {getOfflineItems().length > 0 && (
                <div className="absolute bottom-0 right-0 mt-auto flex flex-row p-4">
                    <Button
                        className="ml-auto mt-auto"
                        onClick={() => {
                            setShowPriceLabelModal(true);
                        }}
                    >
                        <MdRefresh />
                        Reprint label
                    </Button>
                </div>
            )}
            {showPriceLabelModal && (
                <>
                    <div className="absolute left-0 top-0 h-full w-full bg-thriftlyGreyDark opacity-75" />
                    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                        <div className="max-h-3/4 flex flex-col gap-1 gap-4 rounded-thriftlyImage bg-thriftlyOffWhite px-8 py-4">
                            <div className="text-xl font-light uppercase">Printing price label</div>
                            <div
                                ref={labelRef}
                                className="w-full overflow-hidden rounded-thriftlyImage border-2 bg-thriftlyGreyDark"
                            >
                                <LabelRenderer process={getOfflineItems()[getOfflineItems().length - 1]} />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <BarcodeListener
                barcodeData={lastMessage?.data}
                onPricingEntryScan={(scannerId, pricingEntryId) => {
                    if (!sizeSetRef.current) return;

                    // lookup pricing entry
                    const pricingEntry = getPricingEntries().find((entry) => entry.id === pricingEntryId);
                    if (!pricingEntry) {
                        toast.error("Invalid pricing entry");
                        return;
                    }

                    // Handle location pricing
                    let price = pricingEntry.defaultPrice;
                    if (pricingEntry.locationPricing?.[locationId]) {
                        price = pricingEntry.locationPricing[locationId];
                    }

                    createItem({
                        createdAt: new Date().toISOString(),
                        id: uuidv4(),
                        title: "Offline Pricing",
                        processor: PricingProcessor.ProcessorNone,
                        orgId,
                        userId,
                        hangerId: getLocalSettings().selectedHanger?.id,
                        locationId,
                        stationType: getLocalSettings().stationType,
                        price,
                        condition: pricingEntry?.grade as Condition,
                        category: pricingEntry?.category?.name,
                        size: sizeRef.current,
                        taxonomyCode: pricingEntry?.category?.taxonomyCode,
                        productType: pricingEntry?.category?.productType,
                        keepInStore: true,
                    });
                }}
                onFixedPriceScan={async (scannerId, price, condition, category, gender) => {
                    if (!sizeSetRef.current) return;

                    let taxonomyCode: string | undefined = undefined;

                    // resolve taxonomy code to name
                    if (isSet(category)) {
                        if (category?.indexOf("aa-1") === 0) {
                            getTaxonomies().forEach((taxonomy) => {
                                if (taxonomy.code === category) {
                                    category = taxonomy.name;
                                    taxonomyCode = taxonomy.code;
                                }
                            });
                        }
                    }

                    createItem({
                        createdAt: new Date().toISOString(),
                        id: uuidv4(),
                        title: "Offline Pricing",
                        processor: PricingProcessor.ProcessorNone,
                        orgId,
                        userId,
                        hangerId: getLocalSettings().selectedHanger?.id,
                        locationId,
                        stationType: getLocalSettings().stationType,
                        price,
                        condition: condition as Condition,
                        category,
                        size: sizeRef.current,
                        gender,
                        taxonomyCode,
                        keepInStore: true,
                    });
                }}
                onSizeScan={(scannerId, size) => {
                    setSizeSet(true);
                    setSize(size);
                }}
            />
        </>
    );
}
