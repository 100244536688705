import React, { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from "react";
import Webcam from "react-webcam";
import { cameraFormat, cameraResolution, cameraWidth, rotate } from "../../utils/camera";
import { getLocalSettings } from "../../utils/localStorage";
import useCameraPermission from "../common/useCameraPermissions";
import { Button } from "./Button";
import Loader from "./Loader";

export interface CameraRef {
    capture(price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string): void;
}

export default forwardRef(function Camera(
    {
        cameraId,
        width,
        children,
        onImageCapture,
    }: {
        cameraId: string;
        width?: string;
        children?: ReactNode;
        onImageCapture: (
            userImage: string,
            setIsLoading: (isLoading: boolean) => void,
            closeCamera: () => void,
            clearImage: () => void,
            price?: string,
            condition?: string,
            category?: string,
            gender?: string,
            pricingEntryId?: string
        ) => void;
    },
    ref
) {
    const cameraSettings = cameraId === "2" ? getLocalSettings().camera2 : getLocalSettings().camera1;
    const cameraPermission = useCameraPermission();
    const [userImage, setUserImage] = useState<string | null>(null);
    const webcamRef = useRef<Webcam>(null);

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        capture(price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string) {
            capture(price, condition, category, gender, pricingEntryId);
        },
    }));

    const closeCamera = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach((track) => track.stop());
        }
    };

    const clearImage = () => {
        setUserImage(null);
    };

    const capture = React.useCallback(
        async (price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string) => {
            if (webcamRef.current && webcamRef.current.stream) {
                const imageSrc = webcamRef.current.getScreenshot(cameraResolution(cameraSettings));

                rotate(imageSrc!, Number(cameraSettings?.rotation)).then((rotatedImageSrc) => {
                    setUserImage(rotatedImageSrc);
                    onImageCapture(
                        rotatedImageSrc!,
                        setIsLoading,
                        closeCamera,
                        clearImage,
                        price,
                        condition,
                        category,
                        gender,
                        pricingEntryId
                    );
                });
            }
        },
        [webcamRef, setUserImage]
    );

    if (cameraPermission !== "granted") {
        return (
            <div className={`${cameraWidth(cameraSettings, width)} flex flex-col items-center justify-center`}>
                <div className="inline-block flex w-full flex-col gap-4 p-4">
                    <div className="flex w-full items-center justify-center">
                        <Loader />
                    </div>
                    <div className="w-full p-4">
                        <Button className="w-full" onClick={() => window.location.reload()}>
                            Allow camera permission
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${cameraWidth(cameraSettings, width)} flex h-full flex-col items-center`}>
            <div className="w-full overflow-hidden rounded-thriftlyCamera">
                <div className={`inline-block flex flex-col gap-4 p-4`}>
                    {userImage ? (
                        <img
                            className={`z-50 overflow-hidden rounded-thriftlyCamera`}
                            src={userImage || ""}
                            alt="Donation"
                        />
                    ) : (
                        <Webcam
                            audio={false}
                            className={`w-full transform rotate-${cameraSettings?.rotation} overflow-hidden rounded-thriftlyCamera`}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            mirrored={false}
                            screenshotQuality={1}
                            imageSmoothing={false}
                            videoConstraints={{
                                deviceId: cameraSettings?.id,
                                facingMode: "environment",
                                aspectRatio: cameraFormat(cameraSettings),
                            }}
                        />
                    )}
                    {isLoading ? (
                        <div className="flex justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>{children}</>
                    )}
                </div>
            </div>
        </div>
    );
});
