import {
    Categories,
    Containers,
    Departments,
    Locations,
    Organizations,
    Pricing,
    Processes,
    Taxonomies,
    Users,
} from "common";
import { createContext } from "react";

type OrgContextType = {
    login?: Users;
    organizations?: Organizations;
    processes?: Processes;
    containers?: Containers;
    locations?: Locations;
    pricing?: Pricing;
    taxonomies?: Taxonomies;
    categories?: Categories;
    departments?: Departments;
};

export const ApiContext = createContext<OrgContextType>({
    login: undefined,
    organizations: undefined,
    processes: undefined,
    containers: undefined,
    locations: undefined,
    pricing: undefined,
    taxonomies: undefined,
    categories: undefined,
    departments: undefined,
});
