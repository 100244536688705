import { capitalize, Category, Condition } from "common";

export default function PriceButton({
    condition,
    category,
    onClick,
}: {
    condition: Condition;
    category: Category;
    onClick: (price: string) => void;
}) {
    if (category.conditionPrices == undefined || category.conditionPrices[condition] === undefined) {
        return null;
    }

    return (
        <div
            key={condition}
            onClick={() => onClick(category.conditionPrices ? category.conditionPrices[condition] : "")}
            className="flex h-32 w-full cursor-pointer flex-row items-center justify-center gap-8 rounded-lg border border-thriftlyGreyDark bg-white"
        >
            <div className="flex flex-row">
                <img src="/checkmark.svg" className="h-12 w-12" />
                {(condition === Condition.ConditionBetter || condition === Condition.ConditionBest) && (
                    <img src="/checkmark.svg" className="h-12 w-12" />
                )}
                {condition === Condition.ConditionBest && <img src="/checkmark.svg" className="h-12 w-12" />}
            </div>
            <div className="flex flex-col text-center">
                <div className="text-2xl font-bold">{capitalize(condition)}</div>
                <div className="font-light text-thriftlyGrey">
                    {"$" + parseFloat(category.conditionPrices[condition].replace("$", "")).toFixed(2)}
                </div>
            </div>
        </div>
    );
}
