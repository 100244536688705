import {
    Category,
    Department,
    LocationStyles,
    PriceAppConfig,
    PricingEntry,
    PricingProcessor,
    Process,
    StationType,
    Taxonomy,
    User,
} from "common";
import {
    CATEGORIES_KEY,
    CURRENT_COLOR_CYCLE,
    DEPARTMENTS_KEY,
    HANGERS_KEY,
    LABEL_LOGO_KEY,
    LOCAL_SETTINGS_KEY,
    OFFLINE_ITEMS_KEY,
    ORG_STYLES_KEY,
    PRICE_APP_CONFIG_KEY,
    PRICING_ENTRIES_KEY,
    TAXONOMIES_KEY,
    TOP_LEVEL_TAXONOMIES_KEY,
    USER_SESSIONS_KEY,
} from "../constants";
import { UserSession } from "../types";

export function getOrgStyles(): LocationStyles {
    const orgStyles = localStorage.getItem(ORG_STYLES_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {
        primaryColor: "#35644F",
        secondaryColor: "#00B764",
        logo: "https://images.thriftly.com/thriftly-logo.svg",
        logoDarkMode: "https://images.thriftly.com/thriftly-logo-white.svg",
        hidePoweredByThriftly: false,
    };
}

export function setOrgStyles(orgStyles: LocationStyles) {
    localStorage.setItem(ORG_STYLES_KEY, JSON.stringify(orgStyles));
}

export function getPriceAppConfig(): PriceAppConfig {
    const orgStyles = localStorage.getItem(PRICE_APP_CONFIG_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {};
}

export function setPriceAppConfig(priceAppConfig: PriceAppConfig) {
    localStorage.setItem(PRICE_APP_CONFIG_KEY, JSON.stringify(priceAppConfig));
}

export function getDepartments(): Department[] {
    const departments = localStorage.getItem(DEPARTMENTS_KEY);
    if (departments) {
        return JSON.parse(departments);
    }

    return [];
}

export function getHangers(): User[] {
    const hangers = localStorage.getItem(HANGERS_KEY);
    if (hangers) {
        return JSON.parse(hangers);
    }

    return [];
}

export function setHangers(hangers: User[]) {
    localStorage.setItem(HANGERS_KEY, JSON.stringify(hangers));
}

export function setDepartments(departments: Department[]) {
    localStorage.setItem(DEPARTMENTS_KEY, JSON.stringify(departments));
}

export function getCategories(): Category[] {
    const categories = localStorage.getItem(CATEGORIES_KEY);
    if (categories) {
        return JSON.parse(categories);
    }

    return [];
}

export function setCategories(categories: Category[]) {
    localStorage.setItem(CATEGORIES_KEY, JSON.stringify(categories));
}

export function getPricingEntries(): PricingEntry[] {
    const pricingEntries = localStorage.getItem(PRICING_ENTRIES_KEY);
    if (pricingEntries) {
        return JSON.parse(pricingEntries);
    }

    return [];
}

export function setPricingEntries(pricingEntries: PricingEntry[]) {
    localStorage.setItem(PRICING_ENTRIES_KEY, JSON.stringify(pricingEntries));
}

export function setLabelLogo(logo: string | undefined) {
    if (logo) {
        localStorage.setItem(LABEL_LOGO_KEY, logo);
    } else {
        localStorage.removeItem(LABEL_LOGO_KEY);
    }
}

export function getLabelLogo(): string | undefined {
    return localStorage.getItem(LABEL_LOGO_KEY) || undefined;
}

export function setCurrentColorCycle(colorCycle: string | undefined) {
    if (colorCycle) localStorage.setItem(CURRENT_COLOR_CYCLE, colorCycle);
}

export function getCurrentColorCycle(): string | undefined {
    return localStorage.getItem(CURRENT_COLOR_CYCLE) || undefined;
}

export function getTaxonomies(): Taxonomy[] {
    const taxonomies = localStorage.getItem(TAXONOMIES_KEY);
    if (taxonomies) {
        return JSON.parse(taxonomies);
    }

    return [];
}

export function setTaxonomies(taxonomies: Taxonomy[]) {
    localStorage.setItem(TAXONOMIES_KEY, JSON.stringify(taxonomies));
}

export function getTopLevelTaxonomies(): Taxonomy[] {
    const topLevelTaxonomies = localStorage.getItem(TOP_LEVEL_TAXONOMIES_KEY);
    if (topLevelTaxonomies) {
        return JSON.parse(topLevelTaxonomies);
    }

    return [];
}

export function setTopLevelTaxonomies(topLevelTaxonomies: Taxonomy[]) {
    localStorage.setItem(TOP_LEVEL_TAXONOMIES_KEY, JSON.stringify(topLevelTaxonomies));
}

export function setOfflineItems(items: Process[]) {
    localStorage.setItem(OFFLINE_ITEMS_KEY, JSON.stringify(items));
}

export function getOfflineItems(): Process[] {
    const items = localStorage.getItem(OFFLINE_ITEMS_KEY);
    if (items) {
        return JSON.parse(items);
    }

    return [];
}

export type LocalSettings = {
    stationType?: StationType;
    cameraEnabled?: string;
    camera1?: CameraSettings;
    camera2?: CameraSettings;
    aiGenerateProcessor?: PricingProcessor;
    aiAssistantImageCount?: string;
    labelPrinterServer?: string;
    selectedHanger?: User;
};

export type CameraSettings = {
    id?: string;
    rotation?: string;
    format?: string;
    resolution?: string;
};

export function getLocalSettings(): LocalSettings {
    let localSettings: LocalSettings = {};
    const localSettingsValue = localStorage.getItem(LOCAL_SETTINGS_KEY);
    if (localSettingsValue) {
        localSettings = JSON.parse(localSettingsValue);
    }

    return {
        stationType: localSettings.stationType || StationType.StationTypeSoftline,
        cameraEnabled: localSettings.cameraEnabled || "true",
        camera1: localSettings.camera1 || {
            id: "",
            rotation: "0",
            format: "landscape",
            resolution: "480p",
        },
        camera2: localSettings.camera2 || {
            id: "",
            rotation: "0",
            format: "landscape",
            resolution: "480p",
        },
        aiGenerateProcessor: localSettings.aiGenerateProcessor || PricingProcessor.ProcessorGemini,
        aiAssistantImageCount: localSettings.aiAssistantImageCount || "3",
        labelPrinterServer: localSettings.labelPrinterServer || "",
        selectedHanger: localSettings.selectedHanger || undefined,
    };
}

export function setLocalSettings(localSettings: LocalSettings) {
    localStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(localSettings));
}

export function getUserSessions(): UserSession[] {
    const userSessions = localStorage.getItem(USER_SESSIONS_KEY);
    if (userSessions) {
        return JSON.parse(userSessions);
    }

    return [];
}

export function setUserSessions(userSessions: UserSession[]) {
    localStorage.setItem(USER_SESSIONS_KEY, JSON.stringify(userSessions));
}
