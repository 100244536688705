import { CameraIcon } from "@shopify/polaris-icons";

export default function SizeButton({ size, onClick }: { size: string; onClick: (price: string) => void }) {
    return (
        <div
            onClick={() => onClick(size)}
            className="flex h-32 w-32 cursor-pointer flex-col items-center justify-center rounded-lg border border-thriftlyGreyDark bg-white text-center text-2xl font-bold"
        >
            {size === "Auto" && <CameraIcon className="h-12 w-12" />}
            {size}
        </div>
    );
}
