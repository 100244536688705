import { CameraIcon } from "@shopify/polaris-icons";

export default function SearchButton({ onClick }: { onClick: () => void }) {
    return (
        <div
            onClick={onClick}
            className="flex h-16 w-48 cursor-pointer flex-row items-center justify-center gap-4 rounded-lg border border-thriftlyGreyDark bg-white text-2xl font-bold"
        >
            <CameraIcon className="h-12 w-12" />
            Search
        </div>
    );
}
