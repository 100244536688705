import { isMobile } from "react-device-detect";
import { CameraSettings } from "./localStorage";

export const convertUserImage = (userImage: string) => {
    const arr = userImage.split(",");
    // @ts-expect-error could be null
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "image", { type: mime });
};

export const base64toBlob = (base64Data: string, contentType: string) => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
};

export const rotate = async (srcBase64: string, degrees = 90) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return "";
    const image = new Image();

    image.src = srcBase64;
    await image.decode();

    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    return canvas.toDataURL();
};

export const cameraWidth = (cameraSettings: CameraSettings | undefined, width?: string) => {
    if (width) return width;

    if (isMobile) {
        switch (cameraSettings?.format) {
            case "portrait":
                return "w-3/4";
            case "landscape":
                return "w-full";
            case "square":
            default:
                return "w-full";
        }
    }

    switch (cameraSettings?.format) {
        case "portrait":
            return "w-1/5";
        case "landscape":
            return "w-1/2";
        case "square":
        default:
            return "w-1/3";
    }
};

export const cameraFormat = (cameraSettings: CameraSettings | undefined) => {
    if (isMobile) {
        switch (cameraSettings?.format) {
            case "portrait":
                return 3 / 2;
            case "landscape":
                return 9 / 16;
            case "square":
            default:
                return 1;
        }
    }

    switch (cameraSettings?.format) {
        case "portrait":
            return 2 / 3;
        case "landscape":
            return 16 / 9;
        case "square":
        default:
            return 1;
    }
};

export const cameraResolution = (cameraSettings: CameraSettings | undefined) => {
    switch (cameraSettings?.resolution) {
        case "720p":
            switch (cameraSettings?.format) {
                case "portrait":
                    return { width: 480, height: 720 };
                case "landscape":
                    return { width: 1280, height: 720 };
                case "square":
                default:
                    return { width: 720, height: 720 };
            }
        case "480p":
            switch (cameraSettings?.format) {
                case "portrait":
                    return { width: 320, height: 480 };
                case "landscape":
                    return { width: 640, height: 480 };
                case "square":
                default:
                    return { width: 480, height: 480 };
            }
        case "1080p":
        default:
            switch (cameraSettings?.format) {
                case "portrait":
                    return { width: 720, height: 1080 };
                case "landscape":
                    return { width: 1920, height: 1080 };
                case "square":
                default:
                    return { width: 1080, height: 1080 };
            }
    }
};
