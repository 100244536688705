type Option = {
    value: string;
    label: string;
};

type Props = {
    capitalize?: boolean;
    label?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    value?: string;
    options: Option[];
    horizontal?: boolean;
};

export default function CustomDropdownBlack(props: Props) {
    const { capitalize, label, placeholder, value, onChange, onBlur, options, horizontal } = props;

    return (
        <div
            className={`flex ${horizontal ? "flex-row items-center" : "flex-col"} gap-2 bg-thriftlyBlackBackground p-2`}
        >
            {label && (
                <label className="ml-2 text-xs font-semibold text-thriftlyOffWhite" htmlFor={label}>
                    {label}
                </label>
            )}
            <div className="flex flex-row rounded-lg border border-2 border-thriftlyGrey">
                <select
                    className={`w-full rounded-md border-none bg-thriftlyBlackBackground px-2 text-thriftlyOffWhite focus:outline-none focus:ring-0 ${capitalize ? "capitalize" : ""}`}
                    name={label}
                    onChange={(e) => onChange && onChange(e.target.value)}
                    onBlur={(e) => onBlur && onBlur(e.target.value)}
                    value={value}
                >
                    {placeholder && (
                        <option value="" disabled hidden>
                            {placeholder}
                        </option>
                    )}
                    {options.map((option) => {
                        return (
                            <option key={option.value} value={option.value} className={capitalize ? "capitalize" : ""}>
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
