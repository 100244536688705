import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import Router from "./Router";

// Google Analytics
const { VITE_GA_MEASUREMENT_ID } = import.meta.env;
if (VITE_GA_MEASUREMENT_ID) {
    ReactGA.initialize(VITE_GA_MEASUREMENT_ID);
}

// Sentry
const { VITE_ENVIRONMENT, VITE_SENTRY_DSN } = import.meta.env;
if (VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: VITE_SENTRY_DSN,
        environment: VITE_ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api.thriftly\.com/],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 10% of the transactions for performance monitoring
        // Session Replay
        replaysSessionSampleRate: 0.01, // 1% of all sesssion will have a replay saved
        replaysOnErrorSampleRate: 1.0, // 100% of all errors will have a replay saved
    });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <RouterProvider router={Router} />
    </React.StrictMode>
);
