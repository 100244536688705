import { AxiosError } from "axios";
import {
    Category,
    Condition,
    Department,
    ErrorResponse,
    isSet,
    NewZebraBrowserPrint,
    PricingStrategy,
    Process,
    ProcessResponse,
    StationType,
    ZebraPrinter,
} from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import Webcam from "react-webcam";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { ScannerResponse } from "../../types";
import { base64toBlob, cameraFormat, cameraResolution, convertUserImage } from "../../utils/camera";
import { getCategories, getDepartments, getLocalSettings } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";
import BrandAlertModal from "./components/BrandAlertModal";
import CategoryTile from "./components/CategoryTile";
import DepartmentTile from "./components/DepartmentTile";
import PriceButton from "./components/PriceButton";
import SearchButton from "./components/SearchButton";
import SizeButton from "./components/SizeButton";

export default function RapidPricing({
    offlineMode,
    barcodeScannerAgentReady,
    lastBarcodeScannerMessage,
    sendBarcodeScannerJsonMessage,
    getItemsCount,
}: {
    offlineMode: boolean;
    barcodeScannerAgentReady: boolean;
    lastBarcodeScannerMessage: MessageEvent<string> | null;
    sendBarcodeScannerJsonMessage: SendJsonMessage;
    getItemsCount: () => void;
}) {
    const { VITE_API_BASE_URL } = import.meta.env;
    const { processes: processApi } = useContext(ApiContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const camera1Ref = useRef<Webcam>(null);
    const camera2Ref = useRef<Webcam>(null);

    const successBeep = new Audio("/beep-success.mp3");
    const errorBeep = new Audio("/beep-error.mp3");

    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();
    const { state } = useLocation();
    const [lastPriceLabelPrinted, setLastPriceLabelPrinted] = useState<string | undefined>(
        state ? state.lastPriceLabelPrinted : undefined
    );
    const [lastProcessPrinted, setLastProcessPrinted] = useState<Process>();

    const [selectedDepartment, setSelectedDepartment] = useState<Department>();
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [selectedCondition, setSelectedCondition] = useState<Condition>();
    const barcodeScannerImage = useRef<string | undefined>();

    const [showOfflineModeModal, setShowOfflineModeModal] = useState<boolean>(false);
    const [showPricingAlertModal, setShowPricingAlertModal] = useState<boolean>(false);
    const isDetectingSize = useRef<boolean>(false);
    const [sizeDetectionFailed, setSizeDetectionFailed] = useState<boolean>(false);

    // When loading the page initially, load items count and get default label printer
    useEffect(() => {
        getItemsCount();

        // get default label printer
        if (canPrint() && !printerLoaded.current) {
            printerLoaded.current = true;

            NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
                .getDefaultPrinter()
                .then((printer) => (selectedPrinter.current = printer))
                .catch((error) => toast.error(`Error getting default printer: ${error}`));
        }
    }, []);

    // When loading the pricing page initially and when toggling station type, automatically select the first department
    useEffect(() => {
        setSelectedDepartment(
            getDepartments().find((department) => department.stationType === getLocalSettings().stationType)
        );
        setSelectedCategory(undefined);
    }, [getLocalSettings().stationType]);

    // Timer for re-entering barcode scanner image mode if applicable for current state (if barcode scanner times out)
    useEffect(() => {
        if (!barcodeScannerAgentReady) return;

        // reset barcode scanner mode every 31 seconds (barcode scanner has 30 second timeout) if ready to scan another size tag
        const intervalId = setInterval(() => {
            if (
                getLocalSettings().stationType === StationType.StationTypeSoftline &&
                barcodeScannerImage.current === undefined && // don't trigger if we have an image
                showPricingAlertModal === false && // don't trigger if we have a pricing alert being shown
                offlineMode === false && // don't trigger if in offline mode
                selectedCategory !== undefined && // don't trigger if no category selected
                (selectedCategory.pricingStrategy === PricingStrategy.PricingStrategyFlatPrice || // only trigger if prompting for a size
                    (selectedCategory.pricingStrategy === PricingStrategy.PricingStrategyGoodBetterBest &&
                        selectedCondition !== undefined))
            )
                sendBarcodeScannerJsonMessage(
                    JSON.stringify({
                        scannerId: "1",
                        commandType: "image_mode",
                    })
                );
        }, 31000);

        return () => clearInterval(intervalId);
    }, [barcodeScannerAgentReady, barcodeScannerImage.current]);

    // Process image data from barcode scanner through websocket
    useEffect(() => {
        if (barcodeScannerAgentReady && lastBarcodeScannerMessage !== null) {
            // ignore if we already have an image we are processing, there is not a category selected, or a pricing alert is being handled
            if (barcodeScannerImage.current !== undefined || selectedCategory === undefined || showPricingAlertModal)
                return;
            try {
                const msg: ScannerResponse = JSON.parse(lastBarcodeScannerMessage.data);
                if (msg.messageType === "image") {
                    barcodeScannerImage.current = msg.image;
                    createProcess(
                        undefined,
                        selectedCategory.price,
                        selectedCategory.id,
                        undefined,
                        selectedCategory.name
                    );
                }
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(`Error parsing scanner response JSON: ${e.message}`);
                }
                toast.error("Unknown error parsing scanner response JSON");
            }
        }
    }, [lastBarcodeScannerMessage]);

    // Display offline mode modal when entering offline mode
    useEffect(() => {
        if (offlineMode) setShowOfflineModeModal(offlineMode);
    }, [offlineMode]);

    const reset = () => {
        getItemsCount();
        setIsLoading(false);
        isDetectingSize.current = false;
        setSizeDetectionFailed(false);
        setSelectedCondition(undefined); // reset condition after each process
        barcodeScannerImage.current = undefined; // reset barcode scanner image after each process
    };

    const createProcess = async (
        pricingEntryId?: string,
        price?: string,
        categoryId?: string,
        condition?: string,
        category?: string,
        gender?: string,
        size?: string
    ) => {
        if (!isDetectingSize.current) setIsLoading(true);

        // TODO: Handle offline mode

        // Take picture(s) of item with barcode scanner and/or camera(s) as available
        let fullFrameImage: File | undefined, tagImage: File | undefined;
        if (getLocalSettings().cameraEnabled === "true") {
            // take picture with full frame image camera (if configured)
            if (isSet(getLocalSettings().camera1?.id) && camera1Ref.current && camera1Ref.current.stream) {
                const image1Src = camera1Ref.current.getScreenshot(cameraResolution(getLocalSettings().camera1));
                if (image1Src) fullFrameImage = convertUserImage(image1Src);
            }

            // use image from barcode scanner (if available), otherwise take picture with tag camera (if configured)
            if (barcodeScannerImage.current !== undefined) {
                tagImage = new File([base64toBlob(barcodeScannerImage.current!, "image/jpg")], "image", {
                    type: "image/jpg",
                });
            } else if (isSet(getLocalSettings().camera2?.id) && camera2Ref.current && camera2Ref.current.stream) {
                const image2Src = camera2Ref.current.getScreenshot(cameraResolution(getLocalSettings().camera2));
                if (image2Src) tagImage = convertUserImage(image2Src);
            }
        }

        await processApi
            ?.processesCreate(
                // send tag image if available (barcode scanner or tag camera), otherwise send full frame image
                { image: tagImage || fullFrameImage },
                {
                    processor: tagImage ? getLocalSettings().aiGenerateProcessor : undefined,
                    hangerId: getLocalSettings().selectedHanger?.id,
                    pricingEntryId, // overrides price, condition, category, stationType
                    categoryId: categoryId || selectedCategory?.id, // overrides category, stationType
                    category: category || selectedCategory?.name,
                    price,
                    condition,
                    size,
                    gender,
                    stationType: getLocalSettings().stationType,
                }
            )
            .then(async ({ data }: { data: ProcessResponse }) => {
                setLastProcessPrinted(data.process);
                // if detecting size, don't print a label if size is missing (skip on brand alert)
                if (data.process?.keepInStore === true && isDetectingSize.current && !isSet(data.process?.size)) {
                    // Play error sound and show toast, and allow user to manually select size as normal
                    errorBeep.play();
                    toast.error("Size not detected. Please select size manually.");
                    isDetectingSize.current = false;
                    setSizeDetectionFailed(true);
                } else {
                    // If flagged for e-commerce, print label and show alert modal
                    if (data.process?.keepInStore === false) {
                        successBeep.play();
                        setShowPricingAlertModal(true);
                    }

                    setLastPriceLabelPrinted(data.process?.id);

                    if (!canPrint() || selectedPrinter.current == undefined) return;
                    const url = `${VITE_API_BASE_URL}/processes/${data.process?.id}/label?print=true`;
                    selectedPrinter.current.printZPL(url).catch((error) => {
                        toast.error(`Error printing label: ${error}`);
                        console.error(`Error printing label: ${error}`);
                    });

                    toast.success("Item successfully priced");
                    reset();
                }

                // Put barcode scanner back into image mode if ready to scan another size tag
                if (
                    getLocalSettings().stationType === StationType.StationTypeSoftline &&
                    barcodeScannerAgentReady &&
                    selectedCategory?.pricingStrategy === PricingStrategy.PricingStrategyFlatPrice
                ) {
                    sendBarcodeScannerJsonMessage(
                        JSON.stringify({
                            scannerId: "1",
                            commandType: "image_mode",
                        })
                    );
                }

                // upload full frame image if a tag image was uploaded
                if (data.process && (tagImage || barcodeScannerImage.current) && fullFrameImage) {
                    processApi
                        ?.imageCreate(data.process.id!, { image: fullFrameImage }, { primary: true })
                        .catch((err: AxiosError) => {
                            toast.error(
                                (err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again."
                            );
                        });
                }
            })
            .catch((err: AxiosError) => {
                toast.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => setIsLoading(false));
    };

    const getBody = () => {
        if (isLoading) {
            return (
                <div className="w-full">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="flex h-full w-full flex-col p-4 align-top">
                <div className="flex flex-row justify-between">
                    <div className="mt-2 flex flex-row">
                        {getDepartments()
                            .filter((department) => department.stationType === getLocalSettings().stationType)
                            .map((department, idx, departments) => (
                                <DepartmentTile
                                    key={department.id}
                                    department={department}
                                    isSelected={selectedDepartment?.id === department.id}
                                    first={idx === 0}
                                    last={idx === departments.length - 1}
                                    onClick={() => {
                                        setSelectedDepartment(department);
                                        setSelectedCategory(undefined);
                                        setSelectedCondition(undefined);
                                    }}
                                />
                            ))}
                    </div>
                    <div className="mb-2">
                        <SearchButton onClick={() => navigate(`${ROUTES.PRICER_PAGE}?search`)} />
                    </div>
                </div>
                <div
                    className={`shrink-1 flex h-full flex-row flex-wrap content-start gap-4 overflow-y-auto overscroll-y-auto rounded-tr-lg border border-thriftlyGreyDark p-4 ${!selectedCategory && isDetectingSize.current ? "rounded-b-lg" : ""}`}
                >
                    {getCategories()
                        .filter((category) => category.departmentId === selectedDepartment?.id)
                        .map((category) => (
                            <CategoryTile
                                key={category.id}
                                category={category}
                                isSelected={selectedCategory?.id === category.id}
                                onClick={() => {
                                    setSelectedCategory(category);
                                    setSelectedCondition(undefined);

                                    // For Flat Pricing, if barcode scanner agent is not reachable and tag camera is enabled, take picture and create process
                                    // Attempt to detect size with tag picture and upload full frame picture (if camera is enabled) asynchronously
                                    // Show loading spinner where size options would be while waiting for response
                                    // If size is not detected, play error sound and show toast, and allow user to manually select size as normal
                                    if (
                                        category.pricingStrategy === PricingStrategy.PricingStrategyFlatPrice &&
                                        !barcodeScannerAgentReady &&
                                        getLocalSettings().camera2?.id
                                    ) {
                                        isDetectingSize.current = true;
                                        createProcess(undefined, category.price, category.id, undefined, category.name);
                                    }
                                    // set image mode for barcode scanner to scan size tag for soft line
                                    else if (
                                        getLocalSettings().stationType === StationType.StationTypeSoftline &&
                                        barcodeScannerAgentReady &&
                                        category.pricingStrategy === PricingStrategy.PricingStrategyFlatPrice
                                    ) {
                                        sendBarcodeScannerJsonMessage(
                                            JSON.stringify({
                                                scannerId: "1",
                                                commandType: "image_mode",
                                            })
                                        );
                                    }
                                }}
                            />
                        ))}
                </div>
                {getOptions()}
            </div>
        );
    };

    const getOptions = () => {
        if (isDetectingSize.current)
            return (
                <div className="flex shrink-0 flex-row flex-wrap justify-center gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                    <Loader />
                </div>
            );

        if (sizeDetectionFailed)
            return (
                <div className="flex shrink-0 flex-row flex-wrap gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                    {selectedCategory?.options?.map((size) => (
                        <SizeButton
                            key={size}
                            size={size}
                            onClick={async (size) => {
                                setSizeDetectionFailed(false);
                                setIsLoading(true);

                                await processApi
                                    ?.processesUpdate(lastProcessPrinted!.id!, { size }, {})
                                    .then(async ({ data }: { data: ProcessResponse }) => {
                                        setLastPriceLabelPrinted(data.process?.id);

                                        if (!canPrint() || selectedPrinter.current == undefined) return;
                                        const url = `${VITE_API_BASE_URL}/processes/${data.process?.id}/label?print=true`;
                                        selectedPrinter.current.printZPL(url).catch((error) => {
                                            toast.error(`Error printing label: ${error}`);
                                            console.error(`Error printing label: ${error}`);
                                        });

                                        toast.success("Item successfully priced");
                                        reset();
                                    })
                                    .catch((err: AxiosError) => {
                                        toast.error(
                                            (err.response?.data as ErrorResponse)?.error ||
                                            "An error occurred. Please try again."
                                        );
                                    })
                                    .finally(() => setIsLoading(false));
                            }}
                        />
                    ))}
                </div>
            );

        switch (selectedCategory?.pricingStrategy) {
            case PricingStrategy.PricingStrategyGoodBetterBest:
                return (
                    <div className="flex shrink-0 flex-row gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                        {[Condition.ConditionGood, Condition.ConditionBetter, Condition.ConditionBest].map(
                            (condition) => (
                                <PriceButton
                                    key={condition}
                                    condition={condition}
                                    category={selectedCategory}
                                    onClick={(price) => {
                                        createProcess(undefined, price, selectedCategory.id, condition);
                                    }}
                                />
                            )
                        )}
                    </div>
                );
            case PricingStrategy.PricingStrategyFlatPrice:
                return (
                    <div className="flex shrink-0 flex-row flex-wrap gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                        {
                            <SizeButton
                                key="auto"
                                size="Auto"
                                onClick={() => {
                                    isDetectingSize.current = true;
                                    createProcess(
                                        undefined,
                                        selectedCategory?.price,
                                        selectedCategory.id,
                                        undefined,
                                        selectedCategory?.name
                                    );
                                }}
                            />
                        }
                        {selectedCategory?.options?.map((size) => (
                            <SizeButton
                                key={size}
                                size={size}
                                onClick={(size) => {
                                    createProcess(
                                        undefined,
                                        selectedCategory.price,
                                        selectedCategory.id,
                                        undefined,
                                        selectedCategory.name,
                                        undefined,
                                        size
                                    );
                                }}
                            />
                        ))}
                    </div>
                );
            case PricingStrategy.PricingStrategyPricePoints:
                return (
                    <div className="flex shrink-0 flex-row flex-wrap gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                        {selectedCategory?.options?.map((price) => (
                            <SizeButton
                                key={price}
                                size={"$" + parseFloat(price.replace("$", "")).toFixed(2)}
                                onClick={(price) => {
                                    createProcess(
                                        undefined,
                                        price,
                                        selectedCategory.id,
                                        undefined,
                                        selectedCategory.name
                                    );
                                }}
                            />
                        ))}
                    </div>
                );
            case PricingStrategy.PricingStrategyGoodBetterBestWithOptions:
                if (!selectedCondition) {
                    return (
                        <div className="flex shrink-0 flex-row gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                            {[Condition.ConditionGood, Condition.ConditionBetter, Condition.ConditionBest].map(
                                (condition) => (
                                    <PriceButton
                                        key={condition}
                                        condition={condition}
                                        category={selectedCategory}
                                        onClick={() => {
                                            setSelectedCondition(condition);

                                            // For GBB with options, if barcode scanner agent is not reachable and tag camera is enabled, take picture and create process
                                            // Attempt to detect size with tag picture and upload full frame picture (if camera is enabled) asynchronously
                                            // Show loading spinner where size options would be while waiting for response
                                            // If size is not detected, play error sound and show toast, and allow user to manually select size as normal
                                            if (
                                                selectedCategory?.pricingStrategy ===
                                                PricingStrategy.PricingStrategyGoodBetterBestWithOptions &&
                                                !barcodeScannerAgentReady &&
                                                getLocalSettings().camera2?.id !== undefined
                                            ) {
                                                const price = selectedCategory.conditionPrices?.[condition];
                                                isDetectingSize.current = true;
                                                createProcess(
                                                    undefined,
                                                    price,
                                                    selectedCategory.id,
                                                    condition,
                                                    selectedCategory.name
                                                );
                                            } else if (
                                                getLocalSettings().stationType === StationType.StationTypeSoftline &&
                                                barcodeScannerAgentReady
                                            ) {
                                                sendBarcodeScannerJsonMessage(
                                                    JSON.stringify({
                                                        scannerId: "1",
                                                        commandType: "image_mode",
                                                    })
                                                );
                                            }
                                        }}
                                    />
                                )
                            )}
                        </div>
                    );
                }

                return (
                    <div className="flex shrink-0 flex-row flex-wrap gap-4 rounded-b-lg border border-thriftlyGreyDark p-4">
                        {selectedCategory?.options?.map((size) => (
                            <SizeButton
                                key={size}
                                size={size}
                                onClick={(size) => {
                                    createProcess(
                                        undefined,
                                        selectedCategory.conditionPrices?.[selectedCondition],
                                        selectedCategory.id,
                                        selectedCondition,
                                        selectedCategory.name,
                                        undefined,
                                        size
                                    );
                                }}
                            />
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {getBody()}
            {lastPriceLabelPrinted && (
                <div className="absolute bottom-0 right-0 mt-auto flex flex-row p-4">
                    <Button
                        className="ml-auto mt-auto"
                        onClick={() => {
                            if (!canPrint() || selectedPrinter.current == undefined) return;
                            const url = `${VITE_API_BASE_URL}/processes/${lastPriceLabelPrinted}/label?print=true`;
                            selectedPrinter.current.printZPL(url).catch((error) => {
                                toast.error(`Error printing label: ${error}`);
                                console.error(`Error printing label: ${error}`);
                            });
                        }}
                    >
                        <MdRefresh />
                        Reprint label
                    </Button>
                </div>
            )}
            {showOfflineModeModal && <>TODO</>}
            {showPricingAlertModal && lastProcessPrinted && (
                <BrandAlertModal
                    process={lastProcessPrinted}
                    onOverride={async () => {
                        // set keep in store to true and reprint label
                        await processApi
                            ?.processesUpdate(lastProcessPrinted.id!, {
                                keepInStore: true,
                            })
                            .then(async (resp) => {
                                setLastPriceLabelPrinted(lastProcessPrinted.id);

                                if (!canPrint() || selectedPrinter.current == undefined) return;
                                const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                selectedPrinter.current.printZPL(url).catch((error) => {
                                    toast.error(`Error printing label: ${error}`);
                                    console.error(`Error printing label: ${error}`);
                                });
                            })
                            .catch((err: AxiosError) => {
                                toast.error(
                                    (err.response?.data as ErrorResponse)?.error ||
                                    "An error occurred. Please try again."
                                );
                            })
                            .finally(() => {
                                setShowPricingAlertModal(false);
                            });
                    }}
                    onClose={() => {
                        setShowPricingAlertModal(false);
                    }}
                />
            )}
            {getLocalSettings().cameraEnabled === "true" && isSet(getLocalSettings().camera1?.id) && (
                <div className="absolute left-0 top-0 ml-32">
                    <Webcam
                        audio={false}
                        className={`absolute h-32 w-full transform rotate-${getLocalSettings().camera1?.rotation} overflow-hidden rounded-thriftlyCamera`}
                        ref={camera1Ref}
                        screenshotFormat="image/png"
                        mirrored={false}
                        screenshotQuality={1}
                        imageSmoothing={false}
                        videoConstraints={{
                            deviceId: getLocalSettings().camera1?.id,
                            facingMode: "environment",
                            aspectRatio: cameraFormat(getLocalSettings().camera1),
                        }}
                    />
                </div>
            )}
            {getLocalSettings().cameraEnabled === "true" && isSet(getLocalSettings().camera2?.id) && (
                <div className="absolute left-0 top-0 ml-32">
                    <Webcam
                        audio={false}
                        className={`absolute h-32 w-full transform rotate-${getLocalSettings().camera1?.rotation} overflow-hidden rounded-thriftlyCamera`}
                        ref={camera2Ref}
                        screenshotFormat="image/png"
                        mirrored={false}
                        screenshotQuality={1}
                        imageSmoothing={false}
                        videoConstraints={{
                            deviceId: getLocalSettings().camera2?.id,
                            facingMode: "environment",
                            aspectRatio: cameraFormat(getLocalSettings().camera2),
                        }}
                    />
                </div>
            )}
            <BarcodeListener
                onFixedPriceScan={(scannerId, price, condition, category, gender) => {
                    createProcess(undefined, price, undefined, condition, category, gender);
                }}
                onPricingEntryScan={(scannerId, pricingEntryId) => {
                    createProcess(pricingEntryId);
                }}
            />
        </>
    );
}
