type Option = {
    value: string;
    label: string;
};

type Props = {
    capitalize?: boolean;
    label?: string;
    placeholder: string;
    borderColor?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    value?: string;
    options: Option[];
};

export default function GeneratedDropdown(props: Props) {
    const { capitalize, label, placeholder, borderColor, value, onChange, onBlur, options } = props;

    const getBorderColor = () => {
        if (borderColor) {
            return borderColor;
        }
        return "border-thriftlyGrey";
    };

    return (
        <div
            className={`flex w-full flex-col gap-2 rounded-lg border-2 ${getBorderColor()} bg-thriftlyBlackBackground p-2`}
        >
            {label && (
                <label className="ml-2 text-xs font-semibold text-thriftlyGreyLight" htmlFor={label}>
                    {label}
                </label>
            )}
            <div className="flex flex-row">
                <select
                    className={`w-full rounded-md border-none bg-thriftlyBlackBackground px-2 text-thriftlyOffWhite focus:outline-none focus:ring-0 ${capitalize ? "capitalize" : ""}`}
                    name={label}
                    onChange={(e) => onChange && onChange(e.target.value)}
                    onBlur={(e) => onBlur && onBlur(e.target.value)}
                    value={value}
                >
                    {placeholder && (
                        <option key="placeholder" value="" disabled hidden>
                            {placeholder}
                        </option>
                    )}
                    {options.map((option) => {
                        return (
                            <option key={option.value} value={option.value} className={capitalize ? "capitalize" : ""}>
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
